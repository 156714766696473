body.hp {
  //background: #41A62A !important;
  color: #FFF;

  a {
    color: #FFF;
  }

  .container {
    background: transparent;
    border-top: none;
  }

  .homeblock {
    padding-top: 4rem;
    clear: both;
    font-size: 19px;
    line-height: 1.4em;

    .text {
      float: left;
      width: calc(70% - 4px);
      box-sizing: border-box;

      h2, h3, .h2, .h3 {
        margin-top: 0;
        height: auto;
        color: #FFF;
        font-weight: normal;
      }

      h2, .h2 {
        font-size: 32px;
      }

      h3, .h3 {
        font-size: 27px;
        text-decoration: underline;
      }

      h2, p {
        margin-bottom: 2rem;
      }

      h1 {
        font-weight: normal;
        font-size: 3.4rem;
        line-height: 4rem;
        display: block;
      }
    }

    .illustration {
      float: left;
      width: 30%;
      box-sizing: border-box;
      padding-left: 10%;

      img, svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &.first {
      .text {
        width: calc(60% - 4px);
        //padding-bottom: 4em;
        //padding-right: 10%;

        svg {
          height: auto;
          margin-bottom: 2rem;
          width: auto;
          max-width: calc(100vw - 6em);

          .stl1 {
            fill: #FFF;
          }
        }

        .titles {
          padding-bottom: 3rem;
        }

        p.btns {
          margin-bottom: 0;
          margin-top: 2em;

          a {
            margin-bottom: 1em;
          }
        }

        .slide {
          background: #41A62A;
          height: auto;
          align-self: stretch;
        }
      }

      .hpinfo {
        background: RGBA(255, 255, 255, 0.4);
        color: $darkGrey;
        border-radius: 0.5rem;
        padding: 1.4rem 2rem;
        margin-top: 5rem;
        font-size: 1rem;

        &.micronews {
          color: #010101;

          svg {
            height: 1rem;
            margin: 0;
            padding-top: 5px;
            padding-right: 4px;
          }

        }

        p.result {
          padding-top: 1em;
          margin-bottom: 0;

          &.ok {
            color: #43E0BD;
          }

          &.ko {
            color: #E5352D;
          }
        }

        .simplebar-scrollbar, .simplebar-scrollbar:before {
          transition: none;
        }

        ul {
          li {
            border-top: 1px dotted $globalGrey;
            display: flex;
            padding: 0.2em 0 0.3em;

            &:first-child {
              border: none;
            }

            .news-text {
              color: $darkGrey;
              padding-left: 4px;
            }
          }
        }
      }

      .illustration {
        width: 40%;
        padding-bottom: 4em;
        padding-left: 0;
      }
    }

    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }

  form {
    input[type="text"], input[type="email"] {
      background: none;
      color: #FFF;
      border: 2px solid $secondaryColor;
      border-width: 0 0 2px 0;
      font-size: 1em;
      margin-right: 1em;
      margin-bottom: 1em;
    }

    button:not(.btn) {
      border: 2px solid white;
      background: none;
      color: #FFF;
      padding: 1px 1em;
      cursor: pointer;
    }

    button.btn {
      padding: 0.5em 2em;
    }
  }

  .pictos {
    display: flex;
    padding-top: 4rem;

    .picto {
      text-align: center;
      padding: 0 2.6vw 2rem;
      flex-basis: 0;
      flex-grow: 1;


      .image {
        max-width: 258px;
        margin: auto;
        padding-bottom: 5rem;

        svg {
          width: 100%;
          height: auto;

          .st0 {
            stroke: #FFF;
          }

          path {
            fill: #FFF;
          }
        }
      }
    }
  }
  .note {
    color: #FFF;
  }
}
.m-illustration {
  display: none;
}

@media screen and (max-width: 1024px) {
  body.hp {
    .homeblock {
      .text {
        width: calc(60% - 4px);

        h1 {
          font-size: 2rem;
          line-height: 3rem;
        }
      }

      .illustration {
        width: 40%;
      }

      &.first {
        .text {
          width: calc(50% - 4px);
          margin: 0;
        }

        .illustration {
          width: 50%;
        }
      }

      .hpinfo {
        position: relative;
        width: calc(100vw - 8rem);
      }
    }
  }
}

@media screen and (max-width: 764px) {
  body.hp {
    .homeblock {
      .text {
        float: none;
        margin: auto;
        width: auto;
      }

      .illustration {
        display: none;
        float: none;
        margin: auto;
        padding: 0;
        width: 70%;
        max-width: 200px;
      }
      .m-illustration {
        display: block;
        svg {
          display: block;
          margin: 0 auto !important;
          height: auto !important;
          width: 90%;
        }
      }

      &.first {
        .text {
          float: none;
          margin: auto;
          width: auto;
          padding-bottom: 0;
          padding-right: 0;

          p.btns {
            display: flex;
            justify-content: space-between;
          }
        }

        .illustration {
          float: none;
          margin: auto;
          padding: 0;
          width: 70%;
          max-width: 200px;
        }

        .hpinfo {
          &.micronews {
            svg {
              display: none;
            }
          }
        }
      }

      .hpinfo {
        box-sizing: border-box;
        width: 100%;
        padding: 1.4rem 1rem;
      }
    }

    .pictos {
      display: block;

      .picto {
        .image {
          padding-bottom: 2.5em;

        }

        .texte {
          max-width: 460px;
          margin: {
            left: auto;
            right: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  body.hp {
    .homeblock {
      &.first {
        .text {
          p.btns {
            display: block;

            a {
              display: block;
              max-width: 250px;
              margin: auto;
              margin-bottom: 1em;
            }
          }
        }
      }
    }
  }
}