@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url('../fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}