@media screen and (max-width: 764px) {
  body {
    overflow-x: hidden;
  }
  html, body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  h3 {
    span.subtitle {
      display: block;
      padding-top: 0.3em;

      &:before {
        content: ""
      }
    }
  }

  #mobilemenucontainer {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 400;

    &.open {
      left: 0%;

      #mobilemenu {
        right: 0px;
      }
    }

    #mobilemenu {
      transition: all 0.3s;
      position: absolute;
      right: 0px;
      width: 300px;
      top: 0;
      bottom: 0;
      background: $globalGrey;
      color: #FFF;
      box-shadow: 0px 0px 5px 0px $mainColor;

      .options {
        float: right;
      }

      #mobileuserblock {
        padding: 1em 2em;
        background: #FFF;

        .user_element {
          display: block;
          margin: 0;
        }

        p.user_element {
          color: #000;
          padding-top: 1em;
          padding-bottom: 2em;
        }
      }

      ul {
        li {
          border-top: 1px solid $globalGrey;

          &:first-child {
            border: none;
          }

          a {
            display: block;
            padding: 1em 0;
            color: $globalGrey;
            text-decoration: none;

            &:hover, &:active, &:focus {
              color: $mainColor;
            }
          }
        }
      }

    }
  }
  #mobilemenuoverlay.open {
    display: block;
    position: absolute;
    left: 0%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: RGBA(255, 255, 255, 0.4);
    z-index: 390;
  }
  header .tooltipmenu {
    display: none;
  }

  .container {
    display: block;

    &.grid {
      grid-template-rows: 2em 1fr;
    }

    > * {
      padding: 1em;
    }

    header {
      padding: 1em;
    }

    .current {
      padding: 0;
    }

    /*.fil_arriane {
      padding: 0 1em 1em;
    }*/

  }

  header {
    /*
    flex-direction: column;
    align-items: start;
    */
    border-bottom: none;

    .title {
      //margin-bottom: 2em;
      flex-basis: 50%;
      flex-grow: 0.5;
    }

    .user {
      flex-basis: 50%;
      flex-grow: 1;
    }
  }

  .current li, .current .current_name {
    padding: 0 1rem;
  }

  #search {
    width: 100%;
    min-width: 0;
    margin-top: 2em;
  }

  #options {
    /*
    position: absolute;
    top: 1em;
    right: 1em;
    */
    svg {
      //height: 1.5em;
    }
  }

  .user_element {
    display: none;
  }

  #tools {
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    max-height: 25em;
    min-height: 50vh;
    box-sizing: border-box;
    width: 100vw;
    background: #fff;
    transition: .5s ease-in-out;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;

    #close_tools {
      display: block;
      background: $mainColor;
      height: 2em;
      width: 2em;
      position: relative;
      transform: rotate(45deg) translateY(-1.2em);
      transform-origin: top;
    }
  }
  #makedir {
    flex-wrap: wrap;
    justify-content: center;
  }

  #uploader {
    flex-direction: column;
    align-items: center;
  }

  .dashes {
    width: 6em;
  }

  #tools.open {
    transform: translateY(0);
  }

  #tools_button {
    display: block;
  }

  .current li {
    padding-left: 2.5em;

    svg {
      width: 1.5em;
      height: auto;

      *:not(.stma) {
        fill: #808285;
        stroke: transparent;
      }
    }
  }

  .current {
    & > ul {
      & > li {
        height: auto;
        min-height: 4rem;
        flex-wrap: wrap;

        .actions {
          display: none;
          width: 100%;
          text-align: right;
          padding: 1em 0;
          align-content: center;
          justify-content: space-around;
          align-items: center;
        }

        .m-actions-btn {
          cursor: pointer;
          display: flex;
          float: right;
          flex-grow: 1;
          height: 4rem;
          justify-content: right;

          span {
            display: flex;
            flex-grow: 1;
            text-align: right;
            justify-content: flex-end;

            svg {

            }
          }
        }

        & > a, & > form {
          height: 4rem;
        }

        &.open {
          .m-actions-btn {
            span {
              svg {
                transform: rotate(90deg);
              }
            }
          }

          .actions {
            display: flex;
          }
        }
      }
    }
  }
}
