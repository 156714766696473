.headercont {
  background: #FFF;
  position: relative;
}

.headercont + .container {
  border-top: 1px solid $lightGrey;
}

body > section {
  position: relative;

  &.gradient {
    background-image: linear-gradient(180deg, #2AA668 6rem, #00609F 100vh);
  }

  &.bg-dark {
    background-color: $almostBlack;
    color: #FFF;
  }
  & > .container {
    padding: {
      top: 4rem;
      bottom: 4rem;
    }
  }
  p {
    line-height: 1.75em;
    margin-bottom: 1.75em;
  }
  h3 {
    margin-bottom: 4rem;
  }
}

.leaf {
  position: fixed;
  z-index: 0;
  width: 360px;
  height: 360px;
  background: {
    color: transparent;
    image: url("img/leaves.svg");
    position: center center;
    repeat: no-repeat;
    size: contain;
  }


}

#topleaf {
  right: 0;
  top: calc(6rem - 4px);
  position: absolute;

  .login_page & {
    top: 0;
  }
}

#bottomleaf {
  left: 0;
  top: calc(100vh - 360px);
  transform: rotate(180deg);
}

.nopadding {
  padding: 0 !important;
}

.twocols {
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGrey;
  }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    padding: 2rem;

    &:not(:first-child) {
      border-left: 1px solid $lightGrey;
    }

    &.h2 {
      padding-top: 0;
      padding-bottom: 0;

      & > h2 {
      }
      &.bbwhite {
        border-bottom: 1px solid white;
      }
    }
  }
}

footer {
  &.container {
    min-height: 0;
  }
}

@media screen and (max-width: 764px) {
  .twocols {
    display: block;
    .col {
      padding: 1em;
      &:not(:first-child) {
        border-left: none;
      }
    }
  }
}