
@media screen and (max-width: 1023px) {
  #search {
    order: 3;
  }
  header {
    .user {
      flex-basis: 50%;
      flex-grow: 1;
      justify-content: flex-end;
    }
    .title {
      flex-basis: 50%;
      flex-grow: 1;
    }
  }

  .switch {
    display: none;
  }

  #tools {
    grid-column: 1/5;
  }

  .parent {
    display: none;
  }

  .current {
    grid-column: 1/5;
  }

  .optional_tools {
    display: none;
  }

  .current .current_name .mobile_parent_icon {
    display: block;
  }

}
