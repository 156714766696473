/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url(~simplebar/dist/simplebar.min.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: Montserrat;
  src: url("../fonts/Montserrat-Medium.ttf");
  font-weight: 500;
}

.headercont {
  background: #FFF;
  position: relative;
}

.headercont + .container {
  border-top: 1px solid #cecece;
}

body > section {
  position: relative;
}

body > section.gradient {
  background-image: linear-gradient(180deg, #2AA668 6rem, #00609F 100vh);
}

body > section.bg-dark {
  background-color: #242B23;
  color: #FFF;
}

body > section > .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

body > section p {
  line-height: 1.75em;
  margin-bottom: 1.75em;
}

body > section h3 {
  margin-bottom: 4rem;
}

.leaf {
  position: fixed;
  z-index: 0;
  width: 360px;
  height: 360px;
  background-color: transparent;
  background-image: url("img/leaves.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

#topleaf {
  right: 0;
  top: calc(6rem - 4px);
  position: absolute;
}

.login_page #topleaf {
  top: 0;
}

#bottomleaf {
  left: 0;
  top: calc(100vh - 360px);
  transform: rotate(180deg);
}

.nopadding {
  padding: 0 !important;
}

.twocols {
  display: flex;
  flex-direction: row;
}

.twocols:not(:last-child) {
  border-bottom: 1px solid #cecece;
}

.twocols .col {
  flex-basis: 0;
  flex-grow: 1;
  padding: 2rem;
}

.twocols .col:not(:first-child) {
  border-left: 1px solid #cecece;
}

.twocols .col.h2 {
  padding-top: 0;
  padding-bottom: 0;
}

.twocols .col.h2.bbwhite {
  border-bottom: 1px solid white;
}

footer.container {
  min-height: 0;
}

@media screen and (max-width: 764px) {
  .twocols {
    display: block;
  }
  .twocols .col {
    padding: 1em;
  }
  .twocols .col:not(:first-child) {
    border-left: none;
  }
}

body {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  /*
  background: url("./img/background.jpg") no-repeat center fixed;
  background-size: cover;
  */
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-image: linear-gradient(180deg, #2AA668 6rem, #00609F 100vh);
  font-weight: 400;
  overflow-y: scroll;
}

body * {
  transition: 0.2s ease-in-out;
  overflow: visible;
}

body a {
  color: #000;
}

body a:hover {
  color: #231f20;
}

body .text-red {
  color: #FF0000;
}

body .text-grey {
  color: #4d4d4d;
}

strong {
  font-weight: 600;
}

.hidden {
  display: none;
}

.container {
  position: relative;
  max-width: 1376px;
  min-height: calc(100vh - 6rem);
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 4em;
  background: rgba(255, 255, 255, 0.8);
}

.container.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 9em 2em 1fr;
  padding-bottom: unset;
}

.container > * {
  padding: 0 2rem;
}

.container header {
  padding: 1em 2em;
}

.headercont {
  color: #000;
}

.headercont header {
  max-width: 1376px;
  margin: auto;
  padding: 1em 2em;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

#mobilemenucontainer, #mobilemenuoverlay {
  display: none;
}

.current, .parent {
  overflow: auto;
  border-top: 1px solid #BCBEC0;
  padding-bottom: 4em;
}

.current a, .parent a {
  text-decoration: none;
  color: #828282;
}

.current li, .current .current_name, .parent li, .parent .current_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

.current li > a, .current .current_name > a, .parent li > a, .parent .current_name > a {
  display: flex;
  align-items: center;
}

.current li > a svg, .current .current_name > a svg, .parent li > a svg, .parent .current_name > a svg {
  margin-right: 1em;
}

.current .file_item > svg, .parent .file_item > svg {
  margin-right: 1.8em;
}

#notification {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
}

.message, .optional_tools {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;
}

.message a, .message p, .optional_tools a, .optional_tools p {
  color: #fff;
}

.message > svg, .optional_tools > svg {
  margin-right: 1em;
  width: 1.5em;
}

.title {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
}

.title svg {
  height: 2.5em;
  margin-right: .2em;
}

.small {
  font-size: 0.8em;
}

#messages {
  display: none;
  opacity: 0;
  transition: .3s all ease-in-out;
  max-width: 100vw;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.error {
  background: #F07464;
}

.warning {
  background: #F79D5D;
}

.success {
  background: #76CF9A;
}

.optional_tools {
  background: #58595B;
}

.optional_tools .separator {
  color: #fff;
  margin: 0 .5em;
}

svg {
  height: 2rem;
}

.action {
  cursor: pointer;
}

.action svg {
  height: 1.5em;
  width: 1.5em;
  margin-left: 1em;
}

.action svg path {
  fill: #808285;
}

.m-actions-btn {
  display: none;
}

h2 {
  color: #27AAE1;
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

h3 {
  color: #006FFF;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0 1em 0;
}

h3 span.subtitle {
  font-weight: 400;
  color: #FFF;
}

h3 span.subtitle:before {
  content: "| ";
}

h3.border {
  border-left: 4px solid #006FFF;
  padding-left: 0.7rem;
}

h3.mtop, h3.m {
  margin-top: 2.5rem;
}

h3.mbot, h3.m {
  margin-bottom: 2.5rem;
}

h4 {
  font-size: 0.875rem;
  color: #828282;
  font-weight: 500;
  margin: 0 0 1em 0;
}

.t14 {
  font-size: 14px;
}

.t18 {
  font-size: 18px;
}

.t20 {
  font-size: 20px;
}

.t22 {
  font-size: 22px;
}

.lh12 {
  line-height: 1.2em;
}

strong.semi {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

header {
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  flex-wrap: wrap;
}

header > * {
  display: flex;
  align-items: center;
}

header > .user {
  height: 4em;
}

.avatar-container {
  border: 1px dashed #AAA;
  font-size: 2em;
  height: 2em;
  width: 2em;
  border-radius: 3em;
  margin-left: 2em;
  text-align: center;
  padding: 3px;
  margin-top: -3px;
}

.avatar {
  height: 2em;
  width: 2em;
  border-radius: 3em;
  color: #FFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: transparent;
  line-height: 1.95em;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

h1 i {
  font-style: italic;
  margin-right: .15em;
}

.version {
  font-size: .8em;
  font-weight: 400;
  display: none;
}

.user p {
  margin-left: 2em;
}

#tools {
  grid-column: 2/5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2em;
}

#tools #close_tools {
  display: none;
}

#uploader, #mobile_uploader {
  display: flex;
  position: relative;
  height: 100%;
  width: 20em;
}

#uploader input, #mobile_uploader input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
}

#uploader .dashes, #mobile_uploader .dashes {
  padding: .5em;
  border: 1px dashed #27AAE1;
  border-radius: 7em;
  transition: .3s ease-out;
}

#uploader .size, #mobile_uploader .size {
  color: #27AAE1;
  margin-top: .2em;
  font-size: 0.9em;
}

#uploader .upload_ux, #mobile_uploader .upload_ux {
  height: 4em;
  width: 4em;
  background: #27AAE1;
  border-radius: 7em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#uploader .upload_ux svg, #mobile_uploader .upload_ux svg {
  height: 100%;
  width: 100%;
}

#uploader .upload_ux svg *, #mobile_uploader .upload_ux svg * {
  transition: .3s ease-in-out;
}

#uploader #upload-svg, #mobile_uploader #upload-svg {
  display: block;
}

#uploader #loading-svg, #mobile_uploader #loading-svg {
  display: none;
}

#uploader.sending #upload-svg, #mobile_uploader.sending #upload-svg {
  display: none;
}

#uploader.sending #loading-svg, #mobile_uploader.sending #loading-svg {
  display: block;
}

#uploader #uploader_item:hover ~ .dashes, #mobile_uploader #uploader_item:hover ~ .dashes {
  border: 1px solid #27AAE1;
}

#uploader #uploader_item:hover ~ .dashes .drive, #mobile_uploader #uploader_item:hover ~ .dashes .drive {
  stroke-width: 2px;
  stroke: #ffffff;
}

#uploader #uploader_item:hover ~ .dashes .upload_arrow, #mobile_uploader #uploader_item:hover ~ .dashes .upload_arrow {
  transform: rotateX(90deg) translateY(0.5em);
}

#uploader #uploader_item:hover ~ .dashes .doc, #mobile_uploader #uploader_item:hover ~ .dashes .doc {
  transform: scale(1.2) translateY(-0.4em);
  transform-origin: center;
}

#uploader .right-part, #mobile_uploader .right-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  max-width: 14em;
  min-width: 11em;
}

#uploader label, #mobile_uploader label {
  color: #006FFF;
  text-align: center;
  align-self: center;
  padding: 0 1em 0;
  line-height: 1.5;
  word-break: break-word;
}

#uploader button, #mobile_uploader button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  margin-top: 0;
  opacity: 0;
  height: 0;
  border: none;
  transition: .3s ease-in-out;
  cursor: pointer;
}

#uploader button.active, #mobile_uploader button.active {
  height: auto;
  opacity: 1;
  margin-top: 1.5em;
}

.loading {
  animation: loading 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fil_arriane {
  grid-column: 1/5;
  display: flex;
  align-items: center;
}

.fil_arriane a {
  color: #000;
  text-decoration: none;
}

.fil_arriane svg {
  height: 1.3em;
  width: 1em;
}

.fil_arriane li:after {
  content: '/';
  margin: 0 .75em;
}

.fil_arriane li:first-of-type:after, .fil_arriane li:last-of-type:after {
  content: '';
}

.fil_arriane li:last-of-type {
  font-weight: bold;
}

.fil_arriane li:last-of-type a {
  color: #27AAE1;
}

.parent {
  grid-column: 1 / 2;
  background: #F1F2F2;
}

.parent li {
  padding-left: 2em;
  background: url("./img/path.svg") 0.2em 0 no-repeat;
  background-size: contain;
}

.parent li:last-of-type {
  background: url("./img/path_end.svg") 0.2em 0 no-repeat;
}

.parent .foldertype:hover svg *:not(.stma) {
  fill: #27AAE1;
  stroke: transparent;
}

.current {
  grid-column: 2/ 5;
  padding: 0 0 4em 0;
}

.current .current_name {
  justify-content: start;
}

.current .current_name .mobile_parent_icon {
  display: none;
  margin-right: 1rem;
}

.current .current_name .mobile_parent_icon svg {
  height: auto;
  width: 1em;
}

.current li, .current .current_name {
  padding: 0 2rem;
  border-bottom: 1px solid #BCBEC0;
}

.current .filetype:hover svg .cls-fl-1 {
  fill: #231f20;
  stroke: transparent;
}

.current .foldertype:hover svg *:not(.stma) {
  fill: #828282;
  stroke: transparent;
}

#makedir, #mobile_makedir {
  display: flex;
  align-items: center;
}

#makedir > svg, #mobile_makedir > svg {
  height: 3.5em;
  width: 3.5em;
}

#makedir input, #mobile_makedir input {
  background: transparent;
  color: #27AAE1;
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  border: none;
  margin-left: 1em;
  border-bottom: 1px solid #27AAE1;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

#makedir button, #mobile_makedir button {
  cursor: pointer;
  margin: 0 1em;
}

#makedir:hover input, #makedir input:focus {
  padding-bottom: .5em;
}

@media screen and (min-width: 765px) and (max-width: 865px) {
  #makedir_nomDossier {
    width: 9em;
  }
}

.filetype form, .foldertype form {
  display: flex;
}

.filetype form input[type=text], .foldertype form input[type=text] {
  font-family: 'Work Sans', sans-serif;
  font-size: 1em;
  border: none;
  background: none;
  color: #27AAE1;
  border-bottom: 1px solid #27AAE1;
}

.filetype form button[type=submit], .foldertype form button[type=submit] {
  border: none;
  color: #27AAE1;
  font-size: 1.5em;
  background: none;
  cursor: pointer;
}

.filetype form svg {
  width: 1.8em;
  margin-right: 1.6em;
}

.foldertype form svg {
  width: 2em;
  margin-right: 1.5em;
}

.folder_suppresser:hover .couvercle, .file_suppresser:hover .couvercle {
  transform: rotate(55deg);
  transform-origin: 100% 30%;
}

.options:hover {
  transform: rotate(25deg);
}

.options:hover svg * {
  fill: #313131;
}

.login_page .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
}

.login_page .title {
  margin: 2em 0 1em;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}

.login_page h1 {
  color: #27AAE1;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 2em;
  text-align: center;
}

.login_page .alert {
  padding: 0.5em 1em;
  margin-bottom: 2em;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login_form label {
  color: #828282;
  font-size: 1em;
  margin-bottom: .5em;
}

.login_form[name="change_password_form"] label {
  display: block;
}

.login_form[name="change_password_form"] ul {
  margin-bottom: 1em;
}

.login_form input {
  background: #ffffff;
  border-radius: .3em;
  width: 15em;
  padding: .5em 1em;
  margin-bottom: 1em;
  border: 1px solid #7c7c7c;
  font-family: "Work Sans", sans-serif;
  font-size: 1em;
}

.login_form button {
  background: none;
  color: #27AAE1;
  border: none;
  margin: 1em;
  font-size: 1em;
  font-family: 'Work Sans', sans-serif;
  cursor: pointer;
}

.login_form button:hover {
  color: #155574;
}

#tools_button {
  display: none;
  position: fixed;
  height: 3em;
  width: 3em;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

#rgpd {
  position: fixed;
  z-index: 999;
  background: RGBA(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
}

#rgpd.active {
  display: block;
}

#rgpd #bandeau {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  color: #FFF;
}

#rgpd #bandeau .container {
  min-height: 0;
  display: flex;
  padding: 0;
}

#rgpd #bandeau .container > div {
  padding: 2rem;
}

#rgpd #bandeau .container > div.small {
  line-height: 1.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#rgpd #bandeau .container > div.small span {
  font-size: 0.9em;
  color: #cecece;
}

#rgpd #bandeau .container > div a {
  margin: 0.4rem 0;
}

@media screen and (max-width: 764px) {
  #rgpd #bandeau .container {
    flex-direction: column;
    align-items: flex-start;
    align-content: stretch;
  }
  #rgpd #bandeau .container > div {
    flex-grow: 1;
    width: calc(100% - 4rem);
  }
  #rgpd #bandeau .container > div:first-child {
    padding-bottom: 0;
  }
}

.tooltipmenucontainer {
  position: relative;
}

.tooltipmenucontainer .tooltipmenu {
  position: absolute;
  width: 200px;
  left: -84px;
  padding-top: 10px;
  z-index: -1;
  opacity: 0;
  transform: translate(0, -10px);
  transition: opacity 0.3s, transform 0.3s;
}

.tooltipmenucontainer .tooltipmenu.open {
  opacity: 1;
  z-index: 3;
  transform: translate(0, 0);
}

.tooltipmenucontainer .tooltipmenu span.arrow.up {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 16px;
  margin: auto;
  background: transparent url("./img/arrow-up.png") center top no-repeat;
  display: block;
  height: 11px;
}

.tooltipmenucontainer .tooltipmenu.right {
  left: -169px;
}

.tooltipmenucontainer .tooltipmenu.right span.arrow.up {
  left: auto;
  right: 8px;
}

.tooltipmenucontainer .tooltipmenu ul {
  background: #FFF;
  border: 1px solid #D9DADB;
  padding: 0 14px 0.2rem;
  font-size: 14px;
}

.tooltipmenucontainer .tooltipmenu ul li {
  border-top: 1px solid #87888A;
}

.tooltipmenucontainer .tooltipmenu ul li a {
  display: block;
  padding: 0.8rem 0 0.5rem;
  color: #BBB;
  text-decoration: none;
}

.tooltipmenucontainer .tooltipmenu ul li a[href] {
  color: #87888A;
}

.tooltipmenucontainer .tooltipmenu ul li a[href]:hover, .tooltipmenucontainer .tooltipmenu ul li a[href].active {
  color: #00AEEF;
}

.tooltipmenucontainer .tooltipmenu ul li:first-child {
  border-top: none;
}

header .user > .tooltipmenucontainer > a {
  display: block;
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1510px) {
  .user.anon .tooltipmenucontainer .tooltipmenu {
    left: auto;
    right: 0;
  }
  .user.anon .tooltipmenucontainer .tooltipmenu span.arrow.up {
    left: auto;
    right: 8px;
  }
}

@media screen and (max-width: 764px) {
  .user .tooltipmenucontainer .tooltipmenu {
    left: auto;
    right: 0;
  }
  .user .tooltipmenucontainer .tooltipmenu span.arrow.up {
    left: auto;
    right: 8px;
  }
}

#search {
  background: #ffffff;
  border-radius: .1em;
  width: 30%;
  display: flex;
  justify-content: space-between;
  min-width: 17em;
}

#search input {
  padding: .5em 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  background: none;
  color: #7c7c7c;
  border: none;
  width: 100%;
  outline: none;
}

#search button {
  background: none;
  padding: .5em 1em;
  border: none;
  border-left: 1px solid #b5b5b5;
  border-radius: 0 .3em .3em 0;
  cursor: pointer;
  outline: none;
}

#search button:hover {
  background: rgba(175, 169, 170, 0.18);
}

#search button svg {
  height: 1.5em;
}

input:focus {
  outline: none;
  border-color: #4f4f4f;
}

button:focus, a:focus {
  outline: none;
}

.form-group {
  padding-bottom: 1em;
}

.form-group > * {
  display: block;
  margin-bottom: 1em;
}

.form-group input {
  color: #7c7c7c;
  background: #ffffff;
  border-radius: .1em;
  border: 1px solid #CCCCCC;
  padding: .5em 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}

.form-group p {
  margin-bottom: 1em;
}

.file-input {
  position: relative;
  margin-top: 1em;
  /*
  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    opacity: 0;
    cursor: pointer;
  }*/
}

.bgchoice {
  display: inline-block;
  vertical-align: top;
  padding-right: 1em;
}

.bgchoice input {
  float: left;
  margin-left: 0;
  margin-bottom: 0.3em;
}

.bgchoice img {
  width: 100px;
  display: block;
  clear: both;
  cursor: pointer;
}

.btn {
  background: #006FFF;
  color: #FFF;
  border: none;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  border-radius: 2em;
}

.btn.success {
  background: #39B54A;
}

.btn.success:hover {
  background: #2d8e3a;
}

.btn.wire {
  background: none;
  border: 1px solid #828282;
  color: #828282;
}

.btn.wire:hover {
  background: none;
  color: #FFF;
  border-color: #FFF;
}

.btn:hover {
  background: #0059cc;
}

.btn > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.btn > span span {
  flex-grow: 1;
  margin-right: 1em;
}

.btn > span svg, .btn > span .spinner {
  height: 1em;
  margin: 0.7em 1em;
}

.btn > span .spinner path {
  fill: #FFF;
}

button.btn {
  padding: 0;
}

button.btn.nopict {
  padding: 0.7em 1em;
}

a.btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
}

body.hp {
  color: #FFF;
}

body.hp a {
  color: #FFF;
}

body.hp .container {
  background: transparent;
  border-top: none;
}

body.hp .homeblock {
  padding-top: 4rem;
  clear: both;
  font-size: 19px;
  line-height: 1.4em;
}

body.hp .homeblock .text {
  float: left;
  width: calc(70% - 4px);
  box-sizing: border-box;
}

body.hp .homeblock .text h2, body.hp .homeblock .text h3, body.hp .homeblock .text .h2, body.hp .homeblock .text .h3 {
  margin-top: 0;
  height: auto;
  color: #FFF;
  font-weight: normal;
}

body.hp .homeblock .text h2, body.hp .homeblock .text .h2 {
  font-size: 32px;
}

body.hp .homeblock .text h3, body.hp .homeblock .text .h3 {
  font-size: 27px;
  text-decoration: underline;
}

body.hp .homeblock .text h2, body.hp .homeblock .text p {
  margin-bottom: 2rem;
}

body.hp .homeblock .text h1 {
  font-weight: normal;
  font-size: 3.4rem;
  line-height: 4rem;
  display: block;
}

body.hp .homeblock .illustration {
  float: left;
  width: 30%;
  box-sizing: border-box;
  padding-left: 10%;
}

body.hp .homeblock .illustration img, body.hp .homeblock .illustration svg {
  display: block;
  width: 100%;
  height: auto;
}

body.hp .homeblock.first .text {
  width: calc(60% - 4px);
}

body.hp .homeblock.first .text svg {
  height: auto;
  margin-bottom: 2rem;
  width: auto;
  max-width: calc(100vw - 6em);
}

body.hp .homeblock.first .text svg .stl1 {
  fill: #FFF;
}

body.hp .homeblock.first .text .titles {
  padding-bottom: 3rem;
}

body.hp .homeblock.first .text p.btns {
  margin-bottom: 0;
  margin-top: 2em;
}

body.hp .homeblock.first .text p.btns a {
  margin-bottom: 1em;
}

body.hp .homeblock.first .text .slide {
  background: #41A62A;
  height: auto;
  align-self: stretch;
}

body.hp .homeblock.first .hpinfo {
  background: RGBA(255, 255, 255, 0.4);
  color: #4d4d4d;
  border-radius: 0.5rem;
  padding: 1.4rem 2rem;
  margin-top: 5rem;
  font-size: 1rem;
}

body.hp .homeblock.first .hpinfo.micronews {
  color: #010101;
}

body.hp .homeblock.first .hpinfo.micronews svg {
  height: 1rem;
  margin: 0;
  padding-top: 5px;
  padding-right: 4px;
}

body.hp .homeblock.first .hpinfo p.result {
  padding-top: 1em;
  margin-bottom: 0;
}

body.hp .homeblock.first .hpinfo p.result.ok {
  color: #43E0BD;
}

body.hp .homeblock.first .hpinfo p.result.ko {
  color: #E5352D;
}

body.hp .homeblock.first .hpinfo .simplebar-scrollbar, body.hp .homeblock.first .hpinfo .simplebar-scrollbar:before {
  transition: none;
}

body.hp .homeblock.first .hpinfo ul li {
  border-top: 1px dotted #828282;
  display: flex;
  padding: 0.2em 0 0.3em;
}

body.hp .homeblock.first .hpinfo ul li:first-child {
  border: none;
}

body.hp .homeblock.first .hpinfo ul li .news-text {
  color: #4d4d4d;
  padding-left: 4px;
}

body.hp .homeblock.first .illustration {
  width: 40%;
  padding-bottom: 4em;
  padding-left: 0;
}

body.hp .homeblock:after {
  clear: both;
  display: block;
  content: "";
}

body.hp form input[type="text"], body.hp form input[type="email"] {
  background: none;
  color: #FFF;
  border: 2px solid #006FFF;
  border-width: 0 0 2px 0;
  font-size: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}

body.hp form button:not(.btn) {
  border: 2px solid white;
  background: none;
  color: #FFF;
  padding: 1px 1em;
  cursor: pointer;
}

body.hp form button.btn {
  padding: 0.5em 2em;
}

body.hp .pictos {
  display: flex;
  padding-top: 4rem;
}

body.hp .pictos .picto {
  text-align: center;
  padding: 0 2.6vw 2rem;
  flex-basis: 0;
  flex-grow: 1;
}

body.hp .pictos .picto .image {
  max-width: 258px;
  margin: auto;
  padding-bottom: 5rem;
}

body.hp .pictos .picto .image svg {
  width: 100%;
  height: auto;
}

body.hp .pictos .picto .image svg .st0 {
  stroke: #FFF;
}

body.hp .pictos .picto .image svg path {
  fill: #FFF;
}

body.hp .note {
  color: #FFF;
}

.m-illustration {
  display: none;
}

@media screen and (max-width: 1024px) {
  body.hp .homeblock .text {
    width: calc(60% - 4px);
  }
  body.hp .homeblock .text h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
  body.hp .homeblock .illustration {
    width: 40%;
  }
  body.hp .homeblock.first .text {
    width: calc(50% - 4px);
    margin: 0;
  }
  body.hp .homeblock.first .illustration {
    width: 50%;
  }
  body.hp .homeblock .hpinfo {
    position: relative;
    width: calc(100vw - 8rem);
  }
}

@media screen and (max-width: 764px) {
  body.hp .homeblock .text {
    float: none;
    margin: auto;
    width: auto;
  }
  body.hp .homeblock .illustration {
    display: none;
    float: none;
    margin: auto;
    padding: 0;
    width: 70%;
    max-width: 200px;
  }
  body.hp .homeblock .m-illustration {
    display: block;
  }
  body.hp .homeblock .m-illustration svg {
    display: block;
    margin: 0 auto !important;
    height: auto !important;
    width: 90%;
  }
  body.hp .homeblock.first .text {
    float: none;
    margin: auto;
    width: auto;
    padding-bottom: 0;
    padding-right: 0;
  }
  body.hp .homeblock.first .text p.btns {
    display: flex;
    justify-content: space-between;
  }
  body.hp .homeblock.first .illustration {
    float: none;
    margin: auto;
    padding: 0;
    width: 70%;
    max-width: 200px;
  }
  body.hp .homeblock.first .hpinfo.micronews svg {
    display: none;
  }
  body.hp .homeblock .hpinfo {
    box-sizing: border-box;
    width: 100%;
    padding: 1.4rem 1rem;
  }
  body.hp .pictos {
    display: block;
  }
  body.hp .pictos .picto .image {
    padding-bottom: 2.5em;
  }
  body.hp .pictos .picto .texte {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  body.hp .homeblock.first .text p.btns {
    display: block;
  }
  body.hp .homeblock.first .text p.btns a {
    display: block;
    max-width: 250px;
    margin: auto;
    margin-bottom: 1em;
  }
}

body.plans .account-info {
  display: none;
}

.table-plans {
  display: flex;
  flex-direction: row;
  margin: 0 -2rem;
  justify-content: space-between;
}

.table-plans .col-plan {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  margin: 30px 2rem 0;
  padding: 1rem;
  background: #F1F9FF;
  border-radius: 0.5rem;
  color: #4d4d4d;
}

.table-plans .col-plan:before {
  display: block;
  width: 60px;
  height: 60px;
  background: #FFF url("img/clock.svg") center center no-repeat;
  border: 1px solid #BCE0FD;
  border-radius: 30px;
  content: " ";
  margin: calc(-30px - 1em) auto 0;
}

.table-plans .col-plan h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  height: 3em;
  color: #4d4d4d;
  font-weight: 600;
}

.table-plans .col-plan div.desc {
  padding: 2rem 0;
  line-height: 1.5rem;
}

.table-plans .col-plan div.specs {
  padding: 1.375rem 0;
  line-height: 1.5rem;
  font-weight: 600;
  border-width: 1px 0;
  border-style: solid;
  border-color: #BCE0FD;
}

.table-plans .col-plan div.specs label {
  font-weight: 400;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  background-image: url("img/checkbox.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
}

.table-plans .col-plan div.specs label.active {
  background-image: url("img/checkbox_checked.svg");
}

.table-plans .col-plan div.specs label input {
  display: none;
}

.table-plans .col-plan .buttons {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.table-plans .col-plan .buttons a {
  flex-basis: 0;
  margin: 0 5px;
  min-width: 0;
  width: auto;
  display: none;
  padding-left: 1em;
  padding-right: 1em;
}

.table-plans .col-plan .buttons a.visible {
  display: inline-block;
}

div.price > div:first-child > * {
  vertical-align: top;
}

div.price .num {
  font-size: 3.75rem;
  font-weight: 700;
}

div.price span:not(.num) {
  line-height: 2rem;
}

div.price.m {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.note {
  font-size: 0.8em;
  padding-top: 2em;
  color: #828282;
}

.note p {
  margin-bottom: 1rem;
}

span.sup {
  font-size: 0.5em;
  padding-bottom: 0.5em;
}

/* progress bar on settings page */
.progressbar {
  max-width: 400px;
  height: 1em;
}

.progressbar .background {
  background: #CCC;
  height: 1em;
}

.progressbar .background .progression {
  height: 100%;
  width: 5%;
  float: left;
  background: #0C0;
}

/* STRIPE */
#payment-form {
  max-width: 400px;
  padding: 1em 0;
}

#button-result, #card-errors {
  padding-top: 1em;
}

.result-message {
  color: #006FFF;
}

.result-message svg {
  height: auto;
  float: left;
}

.result-message span {
  display: block;
  margin-left: 82px;
}

.result-message a {
  color: #006FFF;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #2699fb;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#svg-tail-spin {
  transition: all .3s;
}

#svg-tail-spin.disabled {
  width: 0;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 1300px) {
  .table-plans {
    margin: 0 -1rem;
  }
  .table-plans .col-plan {
    margin: 30px 1rem 0;
  }
}

@media screen and (max-width: 1023px) {
  .table-plans {
    flex-wrap: wrap;
  }
  .table-plans .col-plan {
    min-width: 280px;
    margin-bottom: 2rem;
  }
}

.greet {
  font-size: 1.625rem;
  color: #006FFF;
  line-height: 2.5rem;
  padding-bottom: 0.5em;
}

.greet span {
  font-weight: 600;
}

.account-info p {
  color: #828282;
}

.account-info div.link-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.account-info div.link-bottom > *:first-child {
  padding-bottom: 1rem;
}

.account-info div.link-bottom a {
  color: #4d4d4d;
}

.col.storage {
  display: flex;
  flex-direction: row;
}

.col.storage > div {
  flex-basis: 180px;
  flex-grow: 1;
}

.col.storage > .progressbarcontainer {
  background: transparent url("img/progress_bg.png") center bottom no-repeat;
  flex-basis: 180px;
  flex-grow: 0;
}

.col.storage > .progressbarcontainer * {
  margin: 0;
  padding: 0;
}

.col.storage > .progressbarcontainer .card {
  height: 150px;
  width: 150px;
  margin: auto;
}

.col.storage > .progressbarcontainer .percent {
  position: relative;
}

.col.storage > .progressbarcontainer .percent .number {
  margin-top: calc(-75px - 1.7rem);
  text-align: center;
  font-size: 52px;
  color: #828282;
  font-weight: 300;
}

.col.storage > .progressbarcontainer .percent .number span {
  font-size: 20px;
  font-weight: 600;
  vertical-align: top;
  line-height: 2rem;
}

.col.storage > .progressbarcontainer .percent svg {
  width: 150px;
  height: 150px;
  position: relative;
  transform: rotate(-90deg);
}

.col.storage > .progressbarcontainer .percent svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 20;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}

.col.storage > .progressbarcontainer .percent svg circle#progress {
  stroke-dasharray: 440;
}

.col.storage > .progressbarcontainer .percent svg circle:not(#progress) {
  stroke: #FFF;
}

@media screen and (max-width: 500px) {
  .col.storage {
    display: block;
  }
  .col.storage > .progressbarcontainer {
    height: 180px;
    margin-top: 32px;
  }
  .col.storage > .progressbarcontainer .percent svg circle:not(#progress) {
    stroke: #F2F2F2;
  }
}

#popin-tree-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  opacity: 0;
  z-index: 5;
  background: RGBA(255, 255, 255, 0.6);
  align-content: space-around;
  align-items: center;
  justify-content: center;
}

#popin-tree-container #popin-tree {
  width: calc(100% - 2em);
  max-width: 500px;
  background: #FFF;
  box-shadow: 0 0 4px 0 RGBA(0, 0, 0, 0.4);
}

#popin-tree-container #popin-tree .header {
  padding: 1em;
}

#popin-tree-container #popin-tree .body {
  padding: 1em;
  border-top: 1px solid #DDD;
  overflow-y: scroll;
  max-height: calc(100vh - 7em);
}

#popin-tree-container #popin-tree .body ul:not(.lvl-0) {
  padding-left: 1em;
  margin: 2px 0;
  border-left: 1px solid #EEE;
}

#popin-tree-container #popin-tree .body ul.lvl-0 {
  margin: 1em 0;
}

#popin-tree-container #popin-tree .body a {
  display: block;
  line-height: 2em;
  text-decoration: none;
  color: #666;
}

#popin-tree-container #popin-tree .body a:hover {
  text-decoration: underline;
  color: #333;
}

@media screen and (max-width: 764px) {
  #popin-tree-container #popin-tree .body a {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1023px) {
  #search {
    order: 3;
  }
  header .user {
    flex-basis: 50%;
    flex-grow: 1;
    justify-content: flex-end;
  }
  header .title {
    flex-basis: 50%;
    flex-grow: 1;
  }
  .switch {
    display: none;
  }
  #tools {
    grid-column: 1/5;
  }
  .parent {
    display: none;
  }
  .current {
    grid-column: 1/5;
  }
  .optional_tools {
    display: none;
  }
  .current .current_name .mobile_parent_icon {
    display: block;
  }
}

@media screen and (max-width: 764px) {
  body {
    overflow-x: hidden;
  }
  html, body {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  h3 span.subtitle {
    display: block;
    padding-top: 0.3em;
  }
  h3 span.subtitle:before {
    content: "";
  }
  #mobilemenucontainer {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 400;
  }
  #mobilemenucontainer.open {
    left: 0%;
  }
  #mobilemenucontainer.open #mobilemenu {
    right: 0px;
  }
  #mobilemenucontainer #mobilemenu {
    transition: all 0.3s;
    position: absolute;
    right: 0px;
    width: 300px;
    top: 0;
    bottom: 0;
    background: #828282;
    color: #FFF;
    box-shadow: 0px 0px 5px 0px #27AAE1;
  }
  #mobilemenucontainer #mobilemenu .options {
    float: right;
  }
  #mobilemenucontainer #mobilemenu #mobileuserblock {
    padding: 1em 2em;
    background: #FFF;
  }
  #mobilemenucontainer #mobilemenu #mobileuserblock .user_element {
    display: block;
    margin: 0;
  }
  #mobilemenucontainer #mobilemenu #mobileuserblock p.user_element {
    color: #000;
    padding-top: 1em;
    padding-bottom: 2em;
  }
  #mobilemenucontainer #mobilemenu ul li {
    border-top: 1px solid #828282;
  }
  #mobilemenucontainer #mobilemenu ul li:first-child {
    border: none;
  }
  #mobilemenucontainer #mobilemenu ul li a {
    display: block;
    padding: 1em 0;
    color: #828282;
    text-decoration: none;
  }
  #mobilemenucontainer #mobilemenu ul li a:hover, #mobilemenucontainer #mobilemenu ul li a:active, #mobilemenucontainer #mobilemenu ul li a:focus {
    color: #27AAE1;
  }
  #mobilemenuoverlay.open {
    display: block;
    position: absolute;
    left: 0%;
    top: 0;
    bottom: 0;
    width: 100%;
    background: RGBA(255, 255, 255, 0.4);
    z-index: 390;
  }
  header .tooltipmenu {
    display: none;
  }
  .container {
    display: block;
    /*.fil_arriane {
      padding: 0 1em 1em;
    }*/
  }
  .container.grid {
    grid-template-rows: 2em 1fr;
  }
  .container > * {
    padding: 1em;
  }
  .container header {
    padding: 1em;
  }
  .container .current {
    padding: 0;
  }
  header {
    /*
    flex-direction: column;
    align-items: start;
    */
    border-bottom: none;
  }
  header .title {
    flex-basis: 50%;
    flex-grow: 0.5;
  }
  header .user {
    flex-basis: 50%;
    flex-grow: 1;
  }
  .current li, .current .current_name {
    padding: 0 1rem;
  }
  #search {
    width: 100%;
    min-width: 0;
    margin-top: 2em;
  }
  #options {
    /*
    position: absolute;
    top: 1em;
    right: 1em;
    */
  }
  .user_element {
    display: none;
  }
  #tools {
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    max-height: 25em;
    min-height: 50vh;
    box-sizing: border-box;
    width: 100vw;
    background: #fff;
    transition: .5s ease-in-out;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
  }
  #tools #close_tools {
    display: block;
    background: #27AAE1;
    height: 2em;
    width: 2em;
    position: relative;
    transform: rotate(45deg) translateY(-1.2em);
    transform-origin: top;
  }
  #makedir {
    flex-wrap: wrap;
    justify-content: center;
  }
  #uploader {
    flex-direction: column;
    align-items: center;
  }
  .dashes {
    width: 6em;
  }
  #tools.open {
    transform: translateY(0);
  }
  #tools_button {
    display: block;
  }
  .current li {
    padding-left: 2.5em;
  }
  .current li svg {
    width: 1.5em;
    height: auto;
  }
  .current li svg *:not(.stma) {
    fill: #808285;
    stroke: transparent;
  }
  .current > ul > li {
    height: auto;
    min-height: 4rem;
    flex-wrap: wrap;
  }
  .current > ul > li .actions {
    display: none;
    width: 100%;
    text-align: right;
    padding: 1em 0;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }
  .current > ul > li .m-actions-btn {
    cursor: pointer;
    display: flex;
    float: right;
    flex-grow: 1;
    height: 4rem;
    justify-content: right;
  }
  .current > ul > li .m-actions-btn span {
    display: flex;
    flex-grow: 1;
    text-align: right;
    justify-content: flex-end;
  }
  .current > ul > li > a, .current > ul > li > form {
    height: 4rem;
  }
  .current > ul > li.open .m-actions-btn span svg {
    transform: rotate(90deg);
  }
  .current > ul > li.open .actions {
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  header .user {
    flex-basis: 32px;
  }
  body:not(.hp) {
    background: #FFF !important;
  }
  body .leaf {
    display: none;
  }
  body.hp section.bg-leaves .container .homeblock.first {
    padding-top: 0;
  }
  body.hp section.bg-leaves .container .homeblock.first .text > svg {
    display: none;
  }
}
