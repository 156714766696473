#popin-tree-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //display: flex;
  display: none;
  opacity: 0;
  z-index: 5;
  background: RGBA(255, 255, 255, 0.6);
  align-content: space-around;
  align-items: center;
  justify-content: center;

  #popin-tree {
    width: calc(100% - 2em);
    max-width: 500px;
    background: #FFF;
    box-shadow: 0 0 4px 0 RGBA(0, 0, 0, 0.4);

    .header {
      padding: 1em;
    }

    .body {
      padding: 1em;
      border-top: 1px solid #DDD;
      overflow-y: scroll;
      max-height: calc(100vh - 7em);

      ul:not(.lvl-0) {
        padding-left: 1em;
        margin: 2px 0;
        border-left: 1px solid #EEE;
      }

      ul.lvl-0 {
        margin: 1em 0;
      }

      a {
        display: block;
        line-height: 2em;
        text-decoration: none;
        color: #666;

        &:hover {
          text-decoration: underline;
          color: #333;
        }
      }
    }
  }
}

@media screen and (max-width: 764px) {
  #popin-tree-container {
    #popin-tree {
      .body {
        a {
          text-decoration: underline;
        }
      }
    }
  }

}