body.plans {
  .account-info {
    display: none;
  }
}
.table-plans {
  display: flex;
  flex-direction: row;
  margin: 0 -2rem;
  justify-content: space-between;

  .col-plan {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    margin: 30px 2rem 0;
    padding: 1rem;
    background: #F1F9FF;
    border-radius: 0.5rem;
    color: $darkGrey;
    &:before {
      display: block;
      width: 60px;
      height: 60px;
      background: #FFF url("img/clock.svg") center center no-repeat;
      border: 1px solid #BCE0FD;
      border-radius: 30px;
      content: " ";
      margin: calc(-30px - 1em) auto 0;
    }

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      height: 3em;
      color: $darkGrey;
      font-weight: 600;
    }

    div.desc {
      padding: 2rem 0;
      line-height: 1.5rem;
    }

    div.specs {
      padding: 1.375rem 0;
      line-height: 1.5rem;
      font-weight: 600;
      border: {
        width: 1px 0;
        style: solid;
        color: #BCE0FD;
      }

      label {
        font-weight: 400;
        padding-left: 2rem;
        cursor: pointer;
        user-select: none;
        background: {
          color: transparent;
          image: url("img/checkbox.svg");
          position: left center;
          repeat: no-repeat;
          size: 1rem 1rem;
        }
        &.active {
          background-image: url("img/checkbox_checked.svg");
        }

        input {
          display: none;
        }
      }
    }

    .buttons {
      margin: {
        left: -5px;
        right: -5px;
      }
      padding: {
        left: 5px;
        right: 5px;
        top: 1.5rem;
        bottom: 0.5rem;
      }

      a {
        flex-basis: 0;
        margin: 0 5px;
        min-width: 0;
        width: auto;
        display: none;
        padding: {
          left: 1em;
          right: 1em;
        }
        &.visible {
          display: inline-block;
        }
      }
    }
  }
}
div.price {
  & > div:first-child > * {
    vertical-align: top;
  }
  .num {
    font-size: 3.75rem;
    font-weight: 700;
  }
  span:not(.num) {
    line-height: 2rem;
  }
  &.m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.note {
  font-size: 0.8em;
  padding-top: 2em;
  color: $globalGrey;
  p {
    margin-bottom: 1rem;
  }
}

span.sup {
  font-size: 0.5em;
  padding-bottom: 0.5em;
}

/* progress bar on settings page */
.progressbar {
  max-width: 400px;
  height: 1em;

  .background {
    background: #CCC;
    height: 1em;

    .progression {
      height: 100%;
      width: 5%;
      float: left;
      background: #0C0;
    }
  }
}


/* STRIPE */
#payment-form {
  max-width: 400px;
  padding: 1em 0;
}

#button-result, #card-errors {
  padding-top: 1em;
}
.result-message {
  color: $secondaryColor;

  svg {
    height: auto;
    float: left;
  }
  span {
    display: block;
    margin-left: 82px;
  }
  a {
    color: $secondaryColor;
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid #2699fb;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#svg-tail-spin {
  transition: all .3s;

  &.disabled {
    width: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 1300px) {
  .table-plans {
    margin: 0 -1rem;
    .col-plan {
      margin: 30px 1rem 0;
    }
  }
}


@media screen and (max-width: 1023px) {
  .table-plans {
    flex-wrap: wrap;
    .col-plan {
      min-width: 280px;
      margin-bottom: 2rem;
    }
  }
}