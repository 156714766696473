

#uploader, #mobile_uploader {
  display: flex;
  position: relative;
  height: 100%;
  width: 20em;

  input {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    height: 100%;
  }

  .dashes {
    padding: .5em;
    border: 1px dashed $mainColor;
    border-radius: 7em;
    transition: .3s ease-out;
  }

  .size {
    color: $mainColor;
    margin-top: .2em;
    font-size: 0.9em;
  }

  .upload_ux {
    height: 4em;
    width: 4em;
    background: $mainColor;
    border-radius: 7em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100%;
      width: 100%;

      * {
        transition: .3s ease-in-out;
      }
    }
  }
  #upload-svg {
    display:block;
  }
  #loading-svg {
    display:none;
  }
  &.sending {
    #upload-svg {
      display:none;
    }
    #loading-svg {
      display:block;
    }
  }

  #uploader_item:hover ~ .dashes {
    border: 1px solid $mainColor;

    .drive {
      stroke-width: 2px;
      stroke: #ffffff;
    }

    .upload_arrow {
      transform: rotateX(90deg) translateY(.5em);
    }

    .doc {
      transform: scale(1.2) translateY(-.4em);
      transform-origin: center;
    }

  }

  .right-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    max-width: 14em;
    min-width: 11em;
  }

  label {
    color: $secondaryColor;
    text-align: center;
    align-self: center;
    padding: 0 1em 0;
    line-height: 1.5;
    word-break: break-word;
  }

  button {
    font-family: 'Work Sans', sans-serif;
    font-size: 1em;
    //color: $mainColor;
    //background: none;
    margin-top: 0;
    opacity: 0;
    height: 0;
    border: none;
    transition: .3s ease-in-out;
    cursor: pointer;
    &.active {
      height: auto;
      opacity: 1;
      margin-top: 1.5em;
    }
  }
}


.loading {
  animation: loading 1.5s;
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  transform-origin: center;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}