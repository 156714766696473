
.greet {
  font-size: 1.625rem;
  color: $secondaryColor;
  line-height: 2.5rem;
  padding-bottom: 0.5em;

  span {
    font-weight: 600;
  }
}

.account-info {
  p {
    color: $globalGrey;
  }

  div.link-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > *:first-child {
      padding-bottom: 1rem;
    }

    a {
      color: $darkGrey;
    }
  }
}

.col.storage {
  display: flex;
  flex-direction: row;

  & > div {
    flex-basis: 180px;
    flex-grow: 1;
  }

  & > .progressbarcontainer {
    background: transparent url('img/progress_bg.png') center bottom no-repeat;

    * {
      margin: 0;
      padding: 0;
    }

    flex-basis: 180px;
    flex-grow: 0;

    .card {
      height: 150px;
      width: 150px;
      margin: auto;
    }

    .percent {
      position: relative;

      .number {
        margin-top: calc(-75px - 1.7rem);
        text-align: center;
        font-size: 52px;
        color: $globalGrey;
        font-weight: 300;

        span {
          font-size: 20px;
          font-weight: 600;
          vertical-align: top;
          line-height: 2rem;
        }
      }

      svg {
        width: 150px;
        height: 150px;
        position: relative;
        transform: rotate(-90deg);

        circle {
          width: 100%;
          height: 100%;
          fill: none;
          stroke-width: 20;
          stroke-linecap: round;
          transform: translate(5px, 5px);
        }

        circle#progress {
          stroke-dasharray: 440;
          //stroke-dashoffset: calc(440px - (440px * 0.05));
          //stroke: #FC0;
        }

        circle:not(#progress) {
          stroke: #FFF;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .col.storage {
    display: block;
    & > .progressbarcontainer {
      height: 180px;
      margin-top: 32px;
      .percent {
        svg {
          circle {
            &:not(#progress) {
              stroke: #F2F2F2;
            }
          }
        }
      }
    }
  }
}