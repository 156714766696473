@media screen and (max-width: 500px) {
  header {
    .user {
      flex-basis: 32px;
    }
  }

  body {
    &:not(.hp) {
      background: #FFF !important;
    }
    .leaf {
      display: none;
    }

    &.hp {
      section.bg-leaves {
        .container {
          .homeblock.first {
            padding-top: 0;
            .text {
              & > svg {
                display: none;
              }
            }
          }
        }
      }
    }
  }


}