//sass --watch public/assets/scss/style.scss:public/assets/css/style.css

//Règles générales--------------------------------------------------------------------------
body {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
  /*
  background: url("./img/background.jpg") no-repeat center fixed;
  background-size: cover;
  */
  background: {
    repeat: no-repeat;
    position: center;
    attachment: fixed;
    image: linear-gradient(180deg, #2AA668 6rem, #00609F 100vh);
  }

  font-weight: 400;
  overflow-y: scroll;

  * {
    transition: $globalTransition;
    overflow: visible;
  }

  a {
    color: #000;

    &:hover {
      color: #231f20;
    }
  }

  .text-red {
    color: #FF0000;
  }
  .text-grey {
    color: $darkGrey;
  }
}

strong {
  font-weight: 600;
}

.hidden {
  display: none;
}

.container {
  position: relative;
  max-width: 1376px;
  min-height: calc(100vh - 6rem);
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 4em;
  background: rgba(255, 255, 255, .8);

  &.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: /*6em*/
            9em 2em 1fr;
    padding-bottom: unset;
  }

  > * {
    padding: 0 2rem;
  }

  header {
    padding: 1em 2em;
  }
}

.headercont {
  //background: #FFF;
  color: #000;

  header {
    max-width: 1376px;
    margin: auto;
    padding: 1em 2em;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
  }
}

#mobilemenucontainer, #mobilemenuoverlay {
  display: none;
}

.current, .parent {
  overflow: auto;
  border-top: 1px solid #BCBEC0;
  padding-bottom: 4em;

  a {
    text-decoration: none;
    color: $globalGrey;
  }

  li, .current_name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;

    > a {
      display: flex;
      align-items: center;

      svg {
        margin-right: 1em;
      }
    }

  }

  .file_item {
    > svg {
      margin-right: 1.8em;
    }
  }

}

#notification {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
}

.message, .optional_tools {
  padding: 1em 2em;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;

  a, p {
    color: #fff;
  }

  > svg {
    margin-right: 1em;
    width: 1.5em;
  }

}
.message p {
}

.title {
  display: flex;
  align-items: flex-start;
  text-decoration: none;

  svg {
    height: 2.5em;
    margin-right: .2em;
  }
}

.small {
  font-size: 0.8em;
}

#messages {
  display: none;
  opacity: 0;
  transition: .3s all ease-in-out;
  max-width: 100vw;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.error {
  background: #F07464;
}

.warning {
  background: #F79D5D;
}

.success {
  background: #76CF9A;
}

.optional_tools {
  background: #58595B;

  .separator {
    color: #fff;
    margin: 0 .5em;
  }
}

svg {
  height: 2rem;
}

.action {
  cursor: pointer;

  svg {
    height: 1.5em;
    width: 1.5em;
    margin-left: 1em;

    path {
      fill: #808285;
    }
  }
}

.m-actions-btn {
  display: none;
}

h2 {
  color: $mainColor;
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

h3 {
  color: $secondaryColor;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0 1em 0;

  span.subtitle {
    font-weight: 400;
    color: #FFF;
    &:before {
      content: "| "
    }
  }

  &.border {
    border-left: 4px solid $secondaryColor;
    padding-left: 0.7rem;
  }
  &.mtop, &.m {
    margin-top: 2.5rem;
  }
  &.mbot, &.m {
    margin-bottom: 2.5rem;
  }
}

h4 {
  font-size: 0.875rem;
  color: $globalGrey;
  font-weight: 500;
  margin: 0 0 1em 0;
}

.t14 {
  font-size: 14px;
}
.t18 {
  font-size: 18px;
}
.t20 {
  font-size: 20px;
}
.t22 {
  font-size: 22px;
}
.lh12 {
  line-height: 1.2em;
}
strong.semi {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

header {
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(255, 255, 255, .5);
  flex-wrap: wrap;

  > * {
    display: flex;
    align-items: center;
  }

  & > .user {
    height: 4em;
  }
}

.avatar-container {
  border: 1px dashed #AAA;
  font-size: 2em;
  height: 2em;
  width: 2em;
  border-radius: 3em;
  margin-left: 2em;
  text-align: center;
  padding: 3px;
  margin-top: -3px;
}

.avatar {
  height: 2em;
  width: 2em;
  border-radius: 3em;
  color: #FFF;
  background: {
    repeat: no-repeat;
    position: center;
    size: cover;
    color: transparent;
  }
  line-height: 1.95em;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: flex-start;

  i {
    font-style: italic;
    margin-right: .15em;
  }
}

.version {
  font-size: .8em;
  font-weight: 400;
  display: none;
}

.user {
  p {
    margin-left: 2em;
  }
}

#tools {
  grid-column: 2/5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2em;

  #close_tools {
    display: none;
  }
}

@import "uploader";

.fil_arriane {
  grid-column: 1/5;
  display: flex;
  align-items: center;

  a {
    color: #000;
    text-decoration: none;
  }

  svg {
    height: 1.3em;
    width: 1em;
  }

  li:after {
    content: '/';
    margin: 0 .75em;
  }

  li:first-of-type:after, li:last-of-type:after {
    content: '';
  }

  li:last-of-type {
    font-weight: bold;

    a {
      color: $mainColor;
    }
  }
}

.parent {
  grid-column: 1 / 2;
  background: #F1F2F2;

  li {
    padding-left: 2em;
    background: url("./img/path.svg") .2em 0 no-repeat;
    background-size: contain;
  }

  li:last-of-type {
    background: url("./img/path_end.svg") .2em 0 no-repeat;
  }

  .foldertype:hover {
    svg *:not(.stma) {
      fill: $mainColor;
      stroke: transparent;
    }
  }
}

.current {
  grid-column: 2/ 5;
  padding: 0 0 4em 0;

  .current_name {
    justify-content: start;

    .mobile_parent_icon {
      display: none;
      margin-right: 1rem;

      svg {
        height: auto;
        width: 1em;
      }
    }
  }

  li, .current_name {
    padding: 0 2rem;
    border-bottom: 1px solid #BCBEC0;
  }

  .filetype {
    &:hover {
      svg .cls-fl-1 {
        fill: #231f20;
        stroke: transparent;
      }
    }
  }

  .foldertype {
    &:hover {
      svg *:not(.stma) {
        fill: $globalGrey;
        stroke: transparent;
      }
    }
  }

}

#makedir, #mobile_makedir {
  > svg {
    height: 3.5em;
    width: 3.5em;
  }

  display: flex;
  align-items: center;

  input {
    background: transparent;
    color: $mainColor;
    font-family: 'Work Sans', sans-serif;
    font-size: 1em;
    border: none;
    margin-left: 1em;
    border-bottom: 1px solid $mainColor;
    font-weight: 500;
    transition: $globalTransition;
  }

  button {
    //background: none;
    //border: none;
    //box-sizing: border-box;
    cursor: pointer;
    margin: 0 1em;
  }
}

#makedir:hover input, #makedir input:focus {
  padding-bottom: .5em;
}
@media screen and (min-width: 765px) and (max-width: 865px) {
  #makedir_nomDossier {
    width: 9em;
  }
}

.filetype, .foldertype {
  form {
    display: flex;

    input[type=text] {
      font-family: 'Work Sans', sans-serif;
      font-size: 1em;
      border: none;
      background: none;
      color: $mainColor;
      border-bottom: 1px solid $mainColor;
    }

    button[type=submit] {
      border: none;
      color: $mainColor;
      font-size: 1.5em;
      background: none;
      cursor: pointer;
    }
  }
}

.filetype {
  form {
    svg {
      width: 1.8em;
      margin-right: 1.6em;
    }
  }
}

.foldertype {
  form {
    svg {
      width: 2em;
      margin-right: 1.5em;
    }
  }
}

.folder_suppresser:hover, .file_suppresser:hover {
  .couvercle {
    transform: rotate(55deg);
    transform-origin: 100% 30%;
  }
}

.options:hover {
  transform: rotate(25deg);

  svg * {
    fill: #313131;
  }
}

.login_page {
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
  }

  .title {
    margin: 2em 0 1em;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
  }

  h1 {
    color: $mainColor;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 2em;
    text-align: center;
  }
  .alert {
    padding: 0.5em 1em;
    margin-bottom: 2em;
  }
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  label {
    color: $globalGrey;
    font-size: 1em;
    margin-bottom: .5em;
  }
  &[name="change_password_form"] {
    label {
      display: block;
    }
    ul {
      margin-bottom: 1em;
    }
  }

  input {
    background: #ffffff;
    border-radius: .3em;
    width: 15em;
    padding: .5em 1em;
    margin-bottom: 1em;
    border: 1px solid #7c7c7c;
    font-family: "Work Sans", sans-serif;
    font-size: 1em;
  }

  button {
    background: none;
    color: $mainColor;
    border: none;
    margin: 1em;
    font-size: 1em;
    font-family: 'Work Sans', sans-serif;
    cursor: pointer;

    &:hover {
      color: #155574;
    }
  }
}

//Version mobile

#tools_button {
  display: none;
  position: fixed;
  height: 3em;
  width: 3em;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}

#rgpd {
  position:fixed;
  z-index: 999;
  background: RGBA(0,0,0,0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  &.active {
    display: block;
  }
  #bandeau {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    color: #FFF;
    .container {
      min-height: 0;
      display: flex;
      padding: 0;
      & > div {
        padding: 2rem;
        &.small {
          line-height: 1.8em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          span {
            font-size: 0.9em;
            color: #cecece;
          }
        }
        a {
          margin: 0.4rem 0;
        }
      }
    }
  }
}
@media screen and (max-width: 764px) {
  #rgpd {
    #bandeau {
      .container {
        flex-direction: column;
        align-items: flex-start;
        align-content: stretch;
        & > div {
          flex-grow: 1;
          width: calc(100% - 4rem);
          &:first-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}